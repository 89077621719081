<template>
  <div>
    <v-card-text>
      <v-card outlined class="white pa-2">
        <v-row row wrap>
          <v-col cols="12" sm="12" class="d-flex">
            <seleccion-asociado
              v-on:onSelectAsociado="onselect"
              v-if="isRolComisiones"
            ></seleccion-asociado>
          </v-col>
        </v-row>

        <v-row wrap v-if="currentUser.asociado.codigo">
          <v-col cols="12" sm="12" class="d-flex">
            <v-card outlined class="mx-auto" width="100%">
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4">
                    {{ currentUser.asociado.codigo }}
                  </div>
                  <v-list-item-title class="headline mb-1">{{
                    currentUser.nombre
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{ nombrecanal }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mx-4"></v-divider>
              <v-card-text>
                <div class="title text--primary">Sucursales</div>
                <v-simple-table fixed-header height="300">
                  <thead>
                    <tr>
                      <th class="text-left">Código</th>
                      <th class="text-left">Nombre</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in sucursales" :key="item.name">
                      <td>{{ item.CodSucursal }}</td>
                      <td>{{ item.Nombre }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-alert v-if="!nombrecanal" text type="info"
          >Es necesario seleccionar un asociado para poder navegar en el
          sistema.</v-alert
        >
      </v-card>
    </v-card-text>
  </div>
</template>

<script>
import seleccionAsociado from "@/components/seleccion_asociado";
import apiAsociado from "@/api/asociados";

export default {
  components: {
    seleccionAsociado,
  },
  data() {
    return {
      url: "",
      nombrecanal: null,
      rowsPerPageItems: [50, 100, 200],
      pagination: {
        rowsPerPage: 100,
      },
      breadcrumb: [
        {
          text: "Selección Asociado",
          disabled: true,
        },
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.user;
    },
    sucursales() {
      if (this.currentUser.sucursales) {
        return this.currentUser.sucursales;
      } else {
        return [];
      }
    },
    isRolComisiones() {
      return this.currentUser.rol === "Comisiones";
    },
  },
  methods: {
    onselect() {
      this.$router.go(-1);
    },
  },
  mounted() {
    // this.$store.dispatch("setBreadcrumbs", this.breadcrumb);
    if (this.currentUser.asociado.canalAsociadoId) {
      apiAsociado
        .getCanalAsociados(this.currentUser.asociado.id)
        .then((response) => {
          this.nombrecanal = response.data[0].Canal.Nombre;
          // return response.data.Canal.Nombre
        })
        .catch(() => {});
    }
  },
};
</script>

