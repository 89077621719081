<template>
  <div>
    <v-select
      :items="canalOptions"
      v-model="canalSelected"
      item-text="Nombre"
      item-value="CanalId"
      append-icon="keyboard_arrow_down"
      label="Canal"
      hint="Seleccione un canal"
      persistent-hint
    ></v-select>
    <br />
    <v-autocomplete
      persistent-hint
      :items="asociadoOptions"
      :filter="customFilter"
      v-model="asociadoSelected"
      hint="Seleccione un asociado"
      item-text="NombreDisplay"
      item-value="AsociadoId"
      placeholder="Asociado"
      append-icon="keyboard_arrow_down"
    ></v-autocomplete>
  </div>
</template>

<script>
import apiAsociado from "@/api/asociados";
import { mapActions } from "vuex";

export default {
  data: () => {
    return {
      user: null,
      asociados: [],
      canalSelected: null,
      asociadoSelected: null,
      customFilter(item, queryText) {
        const hasValue = (val) => (val != null ? val : "");
        const text = hasValue(item.Nombre);
        const query = hasValue(queryText);
        return (
          text
            .toString()
            .toLowerCase()
            .indexOf(query.toString().toLowerCase()) > -1
        );
      },
    };
  },
  computed: {
    userStore() {
      return this.$store.getters.user;
    },
    asociadoOptions() {
      return this.$store.getters.asociados.filter((i) => {
        return !this.canalSelected || i.Canal.CanalId === this.canalSelected;
      });
    },

    canalOptions() {
      return [
        ...new Set(
          this.$store.getters.asociados
            .map((c) => c.Canal)
            .filter((c) => c.TipoCanal == 1)
        ),
      ];
    },
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
  },
  mounted() {
    if (this.userStore.asociado.id != null) {
      this.asociadoSelected = this.userStore.asociado.id;
      this.canalSelected = this.asociadoOptions.find(
        (c) => c.AsociadoId === this.asociadoSelected
      ).Canal.CanalId;
    }
  },
  watch: {
    asociadoSelected: function () {
      let asociadoSel = this.asociadoOptions.find(
        (item) => item.AsociadoId === this.asociadoSelected
      );
      if (this.userStore.asociado.id !== this.asociadoSelected) {
        this.user = this.userStore;
        this.user.asociado.id = asociadoSel.AsociadoId;
        this.user.asociado.codigo = asociadoSel.CodAsociado;
        this.user.nombre = asociadoSel.NombreDisplay;
        apiAsociado
          .getCanalAsociados(asociadoSel.AsociadoId)
          .then((response) => {
            this.user.canalid = response.data[0].CanalId;
            this.user.asociado.canalAsociadoId =
              response.data[0].CanalAsociadoId;
            this.user.canal = response.data[0].Canal.CodCanal;

            this.user.asociado.asociadoComisionGroupID = response.data[0].AsociadosComisionGroupDetalles[0].AsociadoComisionGroupID;

            apiAsociado
              .getSucursalesByCanalAsociadoID(response.data[0].CanalAsociadoId)
              .then((response) => {
                this.user.sucursales = response.data;
              });

            //limpiar data empleado
            try {
              this.user.tarjeta = null;
              this.user.empleadoid = null;
              this.user.seleccion = { Empleados: [], Gestionados: [] };
              this.user.segmento = null;
            } catch {
              console.log("next");
            }

            this.$store.dispatch("setUser", this.user);
            this.$store.dispatch("getCortes");
            this.$store.dispatch("setCanal", "externo");

            let mensaje = {
              title: "Cambio Asociado",
              body: "Actual: " + asociadoSel.Nombre,
            };

            this.setMessage(mensaje);
            this.$emit("onSelectAsociado");
          })
          .catch((error) => {
            this.setError(error);
          });

        this.$router.push({ name: "home" });
      }
    },
  },
};
</script>

<style>
</style>
